import TypeIt from 'typeit';
import {Popbox} from './../vendor/popbox/popbox';

new TypeIt('#typeit', {
    strings: ['developer.', 'designer.', 'creator.', 'father.', 'husband.'],
    nextStringDelay: 4000,
    breakLines: false,
    speed: 150,
    lifeLike: true,
    loop: true
}).go();

let popbox = new Popbox({
    blur:true,
});

let c = document.getElementById('modal');
c.addEventListener('popbox_opening', e => {
});

window.submitForm = function () {
    let elements = document.getElementsByClassName("cf");
    let formData = new FormData();

    for(let i = 0; i < elements.length; i++) {
        formData.append(elements[i].name, elements[i].value);
    }

    let http = new XMLHttpRequest();
    http.onreadystatechange = () => {
        if(http.readyState == 4 && http.status == 200) {
            console.log(http.responseText);
            let element = document.getElementById('contact-form');
            let newEl = document.createElement('p');
            newEl.innerHTML = '<b>Thank you! Message sent successfully.</b>';
            element.parentNode.replaceChild(newEl, element);
        }
    };
    http.open("post", "http://pablomaurer.ch/mail.php");
    http.send(formData);
}